import React, { useEffect, useState } from 'react';
import Joystick from '../components/joystick/joystick';
import { CanvasStaticBoards } from "../components/canvas/canvasStaticBoards";
import useInitOverlay from "../hooks/useInitOverlay";
import getAvailableFeature from "../../domain/repositories/availableFeature";
import useGameInfo from "../hooks/useGameInfo";
import { CanvasEventBoards } from "../components/canvas/canvasEventBoards";
import supabase from "../../../core/supabase/supabase";

function Overlay() {
  useInitOverlay()
  
  useEffect(() => {
    window.onerror = function (msg, url, line, col, error) {
      supabase.overlayLogs.createError({
        comment: 'overlay - общий',
        message: error?.stack ?? msg.toString(),
      })
    }
  }, [])
  
  const { organizer } = useGameInfo()
  const [featureIsAvailable, setAvailabilityFeatures] = useState<boolean>()
  useEffect(() => {
    const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production';
    if (isDev) {
      setAvailabilityFeatures(true)
    } else {
      if (!organizer?.id) return
      getAvailableFeature(organizer.id).then(features => {
        setAvailabilityFeatures(features.OBS_INTEGRATION)
      })
    }
  }, [organizer?.id])
  useEffect(() => {
    const rootDiv = document.getElementById('root')
    const body = document.getElementsByTagName('body')[ 0 ]
    rootDiv?.setAttribute('style', 'background: unset')
    body?.setAttribute('style', 'background: unset; overflow: hidden')
  }, []);
  if (featureIsAvailable === false) {return (<h1>Подписка не активирована</h1>)}
  return (
    <div key={'overlay'} className={'d-flex justify-content-end'} style={{ width: '100%', height: '100%' }}>
      <div
        className={'d-flex justify-content-end'}
        style={{ background: 'unset', width: '100%', height: '100%', position: 'relative' }}
      >
        <CanvasStaticBoards/>
        <CanvasEventBoards/>
      </div>
      {featureIsAvailable && <Joystick key={'FullSizeControlPanel-old'}/>}
    </div>
  );
}

export default Overlay;
