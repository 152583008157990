import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { AppDispatch } from "../../../../../../store/store";
import { useDispatch } from "react-redux";
import useDeleteModal from "../../../../../template-editor/ui/hooks/modals/board/delete";
import useEditModal from "../../../../../template-editor/ui/hooks/modals/board/edit";
import useBoards from "../../../../../template-editor/ui/hooks/useBoards";
import { Board } from "../../../../../core/domain/interfaces/supabase/overlay/entity";
import { favoriteBoard, setActiveBoard } from "../../../../../template-editor/store/templates/templates";
import * as S from './styles'
import UDText from "../../../text";
import { updateBoard } from "../../../../../template-editor/store/templates/actions";
import DownIndicatorIcon from "../../../icon/down-indicator-icon";
import EmptyButton from "../../../emptyButton";
import { increaseClickArea } from "../../../../../template-editor/ui/pages/styles";
import StarIcon from "../../../icon/star-icon";
import NewEditIcon from "../../../icon/new-edit-icon";
import Duplicate from "../../../icon/icons/dublicate";
import Portal from "../../../portal";
import useCopyModal from "../../../../../template-editor/ui/hooks/modals/board/copy";
import ExportIcon from "../../../icon/export-icon";
import { NewTrashIcon } from "../../../icon/new-trash-icon";

interface IProps {
  board: Board
  isEditable: boolean
  onClick: () => void
  isActive?: boolean
  isDefaultTemplate?: boolean
  additionalButton?: React.ReactNode
}

function BoardItem(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { board, isEditable, onClick, isActive, isDefaultTemplate = false, additionalButton } = props
  const { modal: renameModal, open: openRenameModal } = useEditModal()
  const { modal: deleteModal, open: openDeleteModal } = useDeleteModal()
  const { modal: copyModal, open: openCopyModal } = useCopyModal()
  const [isHover, setIsHover] = useState(false)
  const [isDropDown, setIsDropDown] = useState(false)
  const [modalStyles, setModalStyles] = useState<CSSProperties>({})
  const { boards } = useBoards()
  
  const isLast = boards.length === 1
  
  const onEditClick = () => {
    setIsHover(false)
    dispatch(setActiveBoard({ id: board.id }))
    openRenameModal()
  }
  
  const onDeleteClick = () => {
    setIsHover(false)
    dispatch(setActiveBoard({ id: board.id }))
    openDeleteModal()
  }
  
  const onCopyClick = useCallback(() => {
    setIsHover(false)
    dispatch(setActiveBoard({ id: board.id }))
    openCopyModal()
  }, [board.id, dispatch, openCopyModal]);
  
  const onFavoriteClick = useCallback(() => {
    dispatch(updateBoard({ id: board.id, isFavorite: !board.isFavorite }))
    dispatch(favoriteBoard({ id: board.id }))
  }, [board.id, board.isFavorite, dispatch]);
  
  const favoriteIsAvailable = useMemo(() => {
    if (isDefaultTemplate) return false
    let count = 0
    for (const b of boards) {
      b.isFavorite && count++
    }
    return count < 3
  }, [boards, isDefaultTemplate]);
  
  const isEvents = useMemo(() => {
    return board.type === 'events'
  }, [board.type]);
  
  useEffect(() => {
    if (!isActive) {
      setIsDropDown(false)
    }
  }, [isActive]);
  
  const onClickIndicator = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target = event.target as HTMLElement
    const parentBounding = target.parentElement?.getBoundingClientRect();
    if (parentBounding) {
      setModalStyles({
        position: 'absolute',
        top: parentBounding?.top,
        left: parentBounding?.left,
        width: parentBounding?.width,
      })
    }
    
    if (isDropDown) {
    
    }
    setIsDropDown(prevState => !prevState)
  }, [isDropDown]);
  
  useEffect(() => {
    const onScroll = (event: Event) => {
      setIsDropDown(false)
    }
    
    const onClick = (event: MouseEvent) => {
      // setIsDropDown(false)
      const clickOnBoardList = (event.target as HTMLElement)?.closest('#boardsList');
      const clickOnModal = (event.target as HTMLElement)?.closest('#modal');
      if (!clickOnBoardList && !clickOnModal) {
        setIsDropDown(false)
      }
    }
    
    const onEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsDropDown(false)
      }
    }
    
    window.addEventListener("scrollBoardsList", onScroll);
    document.addEventListener("click", onClick);
    window.addEventListener("keydown", onEsc);
    
    return () => {
      window.removeEventListener("scrollBoardsList", onScroll);
      document.removeEventListener("click", onClick);
      window.removeEventListener("keydown", onEsc);
    };
  }, []);
  
  const exportBoard = useCallback(() => {
    const link = document.createElement("a");
    const { id, templateId, alias, createdAt, isFavorite, ...restProperty } = board
    link.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify({ ...restProperty }));
    link.download = `${board.name}.board`;
    link.click();
    link.remove();
  }, [board]);
  
  const dropDownItems = useMemo(() => {
    return items.map(item => {
      let isEnable = true
      let onClick = () => {}
      
      switch (item.id) {
        case 'favorite': {
          isEnable = (favoriteIsAvailable || board.isFavorite) && !isEvents
          onClick = onFavoriteClick
          break;
        }
        case 'delete': {
          isEnable = !isLast
          onClick = onDeleteClick
          break;
        }
        case 'edit': {
          onClick = onEditClick
          break;
        }
        case 'duplicate': {
          onClick = onCopyClick
          break;
        }
        case 'export': {
          onClick = exportBoard
          break
        }
      }
      
      return (
        <S.DropDownItem key={item.id + board.id}>
          <EmptyButton
            onClick={() => {
              onClick()
              setIsDropDown(false)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 12,
            }}
            css={increaseClickArea({ width: 200, height: 36 })}
            color='white'
            icon={<div className={'flex-center'} style={{ width: 24 }}>{item?.icon}</div>}
            disabled={!isEnable}
          >
            {item.text}
          </EmptyButton>
        </S.DropDownItem>
      )
    })
  }, [board.isFavorite, favoriteIsAvailable, isEvents, isLast, onDeleteClick, onEditClick, onFavoriteClick])
  
  return (
    <>
      <S.BoardItem
        onClick={onClick}
        key={board.id}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={isDropDown ? 'squareBoard' : ''}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: isActive ? '#9093AA' : 'transparent',
          color: isActive ? '#FFFFFF' : '#1A1A29',
        }}
      >
        <div className={'d-flex'} style={{ color: 'inherit', gap: 10 }}>
          {board.isFavorite && (
            <StarIcon color={'#F5D956'}/>
          )}
          <UDText style={{ color: 'inherit' }} type={'subhead'}>{board.name}</UDText>
        </div>
        {isEditable && (
          <EmptyButton
            onClick={onClickIndicator}
            css={increaseClickArea({ width: 40, height: 40 })}
          >
            <DownIndicatorIcon
              width={20}
              height={20}
              color={isActive ? '#F5D956' : '#EDEDED'}
              transform={isDropDown ? 'rotate(180)' : 'rotate(0)'}
            />
          </EmptyButton>
        )}
        {additionalButton}
        <Portal wrapperId={board.name}>
          <S.Modal id={'modal'} style={modalStyles} className={isDropDown ? 'show' : ''} isOpen={true}>
            <S.BoardItem
              key={'boardItem-' + board.id}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className={isDropDown ? 'squareBoard' : ''}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: isActive ? '#9093AA' : 'transparent',
                color: isActive ? '#FFFFFF' : '#1A1A29',
                cursor: 'default',
              }}
            >
              <div className={'d-flex'} style={{ color: 'inherit', gap: 10 }}>
                {board.isFavorite && (
                  <StarIcon color={'#F5D956'}/>
                )}
                <UDText style={{ color: 'inherit' }} type={'subhead'}>{board.name}</UDText>
              </div>
              <EmptyButton
                onClick={onClickIndicator}
                css={increaseClickArea({ width: 40, height: 40 })}
              >
                <DownIndicatorIcon
                  width={20}
                  height={20}
                  color={isActive ? '#F5D956' : '#EDEDED'}
                  transform={isDropDown ? 'rotate(180)' : 'rotate(0)'}
                />
              </EmptyButton>
            </S.BoardItem>
            <S.DropDownMenu className={isDropDown ? 'show' : ''}>
              {dropDownItems}
            </S.DropDownMenu>
          </S.Modal>
        </Portal>
        {renameModal}
        {deleteModal}
        {copyModal}
      </S.BoardItem>
    </>
  )
}

export default BoardItem;

const items = [
  {
    id: 'edit',
    text: 'Редактировать',
    icon: <NewEditIcon style={{ minHeight: 24, minWidth: 24 }}/>,
  },
  {
    id: 'favorite',
    text: 'Избранное',
    icon: <StarIcon style={{ minHeight: 24, minWidth: 24 }}/>,
  },
  {
    id: 'duplicate',
    text: 'Дублировать',
    icon: <Duplicate style={{ minHeight: 24, minWidth: 24 }}/>,
  },
  {
    id: 'export',
    text: 'Экспортировать',
    icon: <ExportIcon style={{ minHeight: 24, minWidth: 24 }}/>,
  },
  {
    id: 'delete',
    text: 'Удалить',
    icon: <NewTrashIcon style={{ minHeight: 24, minWidth: 24 }}/>,
  },
];
